.qualifications__filter-panel {
    .c-dropdown__select {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.c-filter-panel {
    background-color: $color--blue-light;
    padding: 20px;
}
