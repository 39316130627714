



/// Convert angle
/// @author Chris Eppstein
/// @param {Number} $value - Value to convert
/// @param {String} $unit - Unit to convert to
/// @return {Number} Converted angle
@function convert-angle($value, $unit) {
  $convertable-units: deg grad turn rad;
  $conversion-factors: 1 (10grad/9deg) (1turn/360deg) (3.1415926rad/180deg);
  @if index($convertable-units, unit($value)) and index($convertable-units, $unit) {
    @return $value
             / nth($conversion-factors, index($convertable-units, unit($value)))
             * nth($conversion-factors, index($convertable-units, $unit));
  }

  @warn "Cannot convert `#{unit($value)}` to `#{$unit}`.";
}

/// Test if `$value` is an angle
/// @param {*} $value - Value to test
/// @return {Bool}
@function is-direction($value) {
  $is-direction: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));

  @return $is-direction or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
@function legacy-direction($value) {
  @if is-direction($value) == false {
    @warn "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be an angle or a direction";
  }

  $conversion-map: (
          to top          : bottom,
          to top right    : bottom left,
          to right top    : left bottom,
          to right        : left,
          to bottom right : top left,
          to right bottom : left top,
          to bottom       : top,
          to bottom left  : top right,
          to left bottom  : right top,
          to left         : right,
          to left top     : right bottom,
          to top left     : bottom right
  );

  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }

  @return 90deg - convert-angle($value, 'deg');
}

/// Mixin printing a linear-gradient
/// as well as a plain color fallback
/// and the `-webkit-` prefixed declaration
/// @access public
/// @param {String | List | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
  @if is-direction($direction) == false {
    $color-stops: ($direction, $color-stops);
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}













@mixin print($declarations) {
  @each $property, $value in $declarations {
    #{$property}: $value
  }
}

// Clearfix
@mixin clearfix() {
  &:before,
  &:after{
    content: " ";
    display: table;
  }

  &:after{
    display: block;
    clear: both;
    height: 1px;
    margin-top: -1px;
    visibility: hidden;
  }
  &{
    *zoom: 1;
  }
}


// REM CALC
@function rem-calc($values, $base-value: $rem-base) {
  $max: length($values);
  @if $max == 1 {
    @return convert-to-rem(nth($values, 1), $base-value);
  }
  $remValues: ();
  @for $i from 1 through $max {
    $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
  }
  @return $remValues;
}

// CONVERT TO REM
@function convert-to-rem($value, $base-value: $rem-base) {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;
  @if ($value == 0rem) {
    $value: 0;
  }
  // Turn 0rem into 0
  @return $value;
}

// STRIP UNIT
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@mixin hide-text() {
  $approximate-em-value: 12px / 1em;
  $wider-than-any-screen: -9999em;
  text-indent: $wider-than-any-screen * $approximate-em-value;
  overflow: hidden;
  text-align: left;
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// icon fitters

@mixin icon-size($size: $icon-size-m) {
  > svg {
    width: $size;
    height: $size;
  }
}

@mixin icon-size-width($width: $icon-size-m) {
  width: $width;
  > svg {
    width: 100%;
    height: auto;
  }
}

@mixin icon-size-height($height: $icon-size-m) {
  height: $height;
  > svg {
    height: 100%;
    width: auto;
  }
}

@mixin max-width($max-width) {
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
}

@mixin multiline-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@function font-size-relative-margin($margin, $font-size-names: (), $font-cap-height: $font--cap-height, $font-sizes: $font--sizes) {
  @each $style in $font-size-names {
    $font-size: map-get($font-sizes, $style);
    $margin: $margin - get-font-size-relative-margin($font-size, $font-cap-height, $font-sizes);
  }
  @return $margin;
}

@function get-font-size-relative-margin($font-size, $font-cap-height: $font--cap-height, $font-sizes: $font--sizes) {
  $line-height-value: map-get($font-size, line-height);
  $font-size-value: map-get($font-size, font-size);
  $line-height-difference: ($line-height-value - $font-size-value) / 2;
  $size-difference: ($font-size-value - ($font-size-value * $font-cap-height)) / 2;
  $margin: $line-height-difference + $size-difference;
  @return $margin;
}

@mixin hover($color: $color--font--default, $hover-color: $color--font--highlight, $selector: '&:hover', $speed: $animation-duration--s) {
  color: $color;
  transition: color $speed $animation-timing-default;
  #{$selector} {
    color: $hover-color;
  }
}


@mixin reset-list() {
  margin: 0;
  list-style: none;
  padding: 0;
}


/// Adds a browser prefix to the property
/// @param {*} $property Property
/// @param {*} $value Value

@mixin css3-prefix($property, $value) {
  -webkit-#{$property}: #{$value};
  -khtml-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
  #{$property}: #{$value};
}



/// Transition
/// @param {List} $properties - Properties
/// @require {mixin} css3-prefix

@mixin transition($properties...) {

  @if length($properties) >= 1 {
    @include css3-prefix('transition', $properties);
  }

  @else {
    @include css3-prefix('transition',  "all 0.2s ease-in-out 0s");
  }
}



/// Keyframes
/// @param {*} $animation-name - Animation name
/// @content [Animation css]

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}


/// Animation
/// @param {*} $str - name duration timing-function delay iteration-count direction fill-mode play-state ([http://www.w3schools.com/cssref/css3_pr_animation.asp](http://www.w3schools.com/cssref/css3_pr_animation.asp))
/// @require {mixin} css3-prefix

@mixin animation($str) {
  @include css3-prefix('animation', $str);
}




@mixin underline {
  @include underline-background;
}

@mixin underline-background($color: $color--text--underline, $width: $text-underline-width, $offset: $text-underline-position, $background: $color--white, $text-underline-gap-minimum: $text-underline-gap-minimum) {
  text-decoration: none;
  background-image: linear-gradient(to top, transparent, transparent $offset, $color $offset, $color calc(#{$offset} + #{$width}), transparent calc(#{$offset} + #{$width}));
  text-shadow:
          #{$text-underline-gap-minimum} 0 $background,
          -#{$text-underline-gap-minimum} 0 $background,
          #{$text-underline-gap-minimum} -#{$text-underline-gap-minimum} $background,
          -#{$text-underline-gap-minimum} -#{$text-underline-gap-minimum} $background,
}

@mixin underline-background-fancy($text-underline-opacity: $text-underline-opacity, $text-underline-color: $color--text--underline, $text-underline-background-color: $text-underline-background-color, $text-underline-position: $text-underline-position, $text-underline-size: $text-underline-width, $text-underline-gap: $text-underline-gap, $text-underline-gap-minimum: $text-underline-gap-minimum) {
  text-decoration: none;
  //display: inline-block;
  box-shadow:
          inset 0 calc(#{$text-underline-position} + #{$text-underline-size}) 0 0 $text-underline-background-color,
          inset 0 $text-underline-position 0 0 rgba($text-underline-background-color, $text-underline-opacity),
          inset 0 $text-underline-position 0 0 $text-underline-color;
  text-shadow:
          $text-underline-gap 0 $text-underline-background-color,
          -$text-underline-gap 0 $text-underline-background-color,
          $text-underline-gap-minimum 0 $text-underline-background-color,
          -$text-underline-gap-minimum 0 $text-underline-background-color;
}

@mixin underline-remove {
  @include underline-background-remove;
}

@mixin underline-background-remove {
  background-image: none;
}

@mixin underline-element() {
  white-space: nowrap;
  text-decoration: none;
  position: relative;

  &:before {
    content: "";
    height: 1px;
    background-color: currentColor;
    position: absolute;
    bottom: -2px;
    width: 100%;
  }
}

@mixin underline-element-remove {
  white-space: normal;
  &:before {
    content: normal;
    display: none;
  }
}


@mixin z-index($key) {
  z-index: map-get($z-index, $key);
}












// Flexbox Containers
//
// The 'flex' value causes an element to generate a block-level flex
// container box.
//
// The 'inline-flex' value causes an element to generate a inline-level
// flex container box.
//
// display: flex | inline-flex
//
// http://w3.org/tr/css3-flexbox/#flex-containers
//
// (Placeholder selectors for each type, for those who rather @extend)

@mixin flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

%flexbox { @include flexbox; }

//----------------------------------

@mixin inline-flex {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

%inline-flex { @include inline-flex; }

//----------------------------------------------------------------------

// Flexbox Direction
//
// The 'flex-direction' property specifies how flex items are placed in
// the flex container, by setting the direction of the flex container's
// main axis. This determines the direction that flex items are laid out in.
//
// Values: row | row-reverse | column | column-reverse
// Default: row
//
// http://w3.org/tr/css3-flexbox/#flex-direction-property

@mixin flex-direction($value: row) {
  @if $value == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
  } @else if $value == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  } @else if $value == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
  }
  -webkit-flex-direction: $value;
  -moz-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}
// Shorter version:
@mixin flex-dir($args...) { @include flex-direction($args...); }

//----------------------------------------------------------------------

// Flexbox Wrap
//
// The 'flex-wrap' property controls whether the flex container is single-line
// or multi-line, and the direction of the cross-axis, which determines
// the direction new lines are stacked in.
//
// Values: nowrap | wrap | wrap-reverse
// Default: nowrap
//
// http://w3.org/tr/css3-flexbox/#flex-wrap-property

@mixin flex-wrap($value: nowrap) {
  // No Webkit Box fallback.
  -webkit-flex-wrap: $value;
  -moz-flex-wrap: $value;
  @if $value == nowrap {
    -ms-flex-wrap: none;
  } @else {
    -ms-flex-wrap: $value;
  }
  flex-wrap: $value;
}

//----------------------------------------------------------------------

// Flexbox Flow (shorthand)
//
// The 'flex-flow' property is a shorthand for setting the 'flex-direction'
// and 'flex-wrap' properties, which together define the flex container's
// main and cross axes.
//
// Values: <flex-direction> | <flex-wrap>
// Default: row nowrap
//
// http://w3.org/tr/css3-flexbox/#flex-flow-property

@mixin flex-flow($values: (row nowrap)) {
  // No Webkit Box fallback.
  -webkit-flex-flow: $values;
  -moz-flex-flow: $values;
  -ms-flex-flow: $values;
  flex-flow: $values;
}

//----------------------------------------------------------------------

// Flexbox Order
//
// The 'order' property controls the order in which flex items appear within
// their flex container, by assigning them to ordinal groups.
//
// Default: 0
//
// http://w3.org/tr/css3-flexbox/#order-property

@mixin order($int: 0) {
  -webkit-box-ordinal-group: $int + 1;
  -webkit-order: $int;
  -moz-order: $int;
  -ms-flex-order: $int;
  order: $int;
}

//----------------------------------------------------------------------

// Flexbox Grow
//
// The 'flex-grow' property sets the flex grow factor. Negative numbers
// are invalid.
//
// Default: 0
//
// http://w3.org/tr/css3-flexbox/#flex-grow-property

@mixin flex-grow($int: 0) {
  -webkit-box-flex: $int;
  -webkit-flex-grow: $int;
  -moz-flex-grow: $int;
  -ms-flex-positive: $int;
  flex-grow: $int;
}

//----------------------------------------------------------------------

// Flexbox Shrink
//
// The 'flex-shrink' property sets the flex shrink factor. Negative numbers
// are invalid.
//
// Default: 1
//
// http://w3.org/tr/css3-flexbox/#flex-shrink-property

@mixin flex-shrink($int: 1) {
  -webkit-flex-shrink: $int;
  -moz-flex-shrink: $int;
  -ms-flex-negative: $int;
  flex-shrink: $int;
}

//----------------------------------------------------------------------

// Flexbox Basis
//
// The 'flex-basis' property sets the flex basis. Negative lengths are invalid.
//
// Values: Like "width"
// Default: auto
//
// http://www.w3.org/TR/css3-flexbox/#flex-basis-property

@mixin flex-basis($value: auto) {
  -webkit-flex-basis: $value;
  -moz-flex-basis: $value;
  -ms-flex-preferred-size: $value;
  flex-basis: $value;
}

//----------------------------------------------------------------------

// Flexbox "Flex" (shorthand)
//
// The 'flex' property specifies the components of a flexible length: the
// flex grow factor and flex shrink factor, and the flex basis. When an
// element is a flex item, 'flex' is consulted instead of the main size
// property to determine the main size of the element. If an element is
// not a flex item, 'flex' has no effect.
//
// Values: none | <flex-grow> <flex-shrink> || <flex-basis>
// Default: See individual properties (1 1 0).
//
// http://w3.org/tr/css3-flexbox/#flex-property

@mixin flex($fg: 1, $fs: null, $fb: null) {

  // Set a variable to be used by box-flex properties
  $fg-boxflex: $fg;

  // Box-Flex only supports a flex-grow value so let's grab the
  // first item in the list and just return that.
  @if type-of($fg) == 'list' {
    $fg-boxflex: nth($fg, 1);
  }

  -webkit-box-flex: $fg-boxflex;
  -webkit-flex: $fg $fs $fb;
  -moz-box-flex: $fg-boxflex;
  -moz-flex: $fg $fs $fb;
  -ms-flex: $fg $fs $fb;
  flex: $fg $fs $fb;
}

//----------------------------------------------------------------------

// Flexbox Justify Content
//
// The 'justify-content' property aligns flex items along the main axis
// of the current line of the flex container. This is done after any flexible
// lengths and any auto margins have been resolved. Typically it helps distribute
// extra free space leftover when either all the flex items on a line are
// inflexible, or are flexible but have reached their maximum size. It also
// exerts some control over the alignment of items when they overflow the line.
//
// Note: 'space-*' values not supported in older syntaxes.
//
// Values: flex-start | flex-end | center | space-between | space-around
// Default: flex-start
//
// http://w3.org/tr/css3-flexbox/#justify-content-property

@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
    -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
  -moz-justify-content: $value;
  justify-content: $value;
}
// Shorter version:
@mixin flex-just($args...) { @include justify-content($args...); }

//----------------------------------------------------------------------

// Flexbox Align Items
//
// Flex items can be aligned in the cross axis of the current line of the
// flex container, similar to 'justify-content' but in the perpendicular
// direction. 'align-items' sets the default alignment for all of the flex
// container's items, including anonymous flex items. 'align-self' allows
// this default alignment to be overridden for individual flex items. (For
// anonymous flex items, 'align-self' always matches the value of 'align-items'
// on their associated flex container.)
//
// Values: flex-start | flex-end | center | baseline | stretch
// Default: stretch
//
// http://w3.org/tr/css3-flexbox/#align-items-property

@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  -moz-align-items: $value;
  align-items: $value;
}

//----------------------------------

// Flexbox Align Self
//
// Values: auto | flex-start | flex-end | center | baseline | stretch
// Default: auto

@mixin align-self($value: auto) {
  // No Webkit Box Fallback.
  -webkit-align-self: $value;
  -moz-align-self: $value;
  @if $value == flex-start {
    -ms-flex-item-align: start;
  } @else if $value == flex-end {
    -ms-flex-item-align: end;
  } @else {
    -ms-flex-item-align: $value;
  }
  align-self: $value;
}

//----------------------------------------------------------------------

// Flexbox Align Content
//
// The 'align-content' property aligns a flex container's lines within the
// flex container when there is extra space in the cross-axis, similar to
// how 'justify-content' aligns individual items within the main-axis. Note,
// this property has no effect when the flexbox has only a single line.
//
// Values: flex-start | flex-end | center | space-between | space-around | stretch
// Default: stretch
//
// http://w3.org/tr/css3-flexbox/#align-content-property

@mixin align-content($value: stretch) {
  // No Webkit Box Fallback.
  -webkit-align-content: $value;
  -moz-align-content: $value;
  @if $value == flex-start {
    -ms-flex-line-pack: start;
  } @else if $value == flex-end {
    -ms-flex-line-pack: end;
  } @else {
    -ms-flex-line-pack: $value;
  }
  align-content: $value;
}


@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
  @include transform(scale($scale));
}
// translate
@mixin translate ($x, $y) {
  @include transform(translate($x, $y));
}
// skew
@mixin skew ($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin ($origin) {
  moz-transform-origin: $origin;
  -o-transform-origin: $origin;
  -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
  transform-origin: $origin;
}

//---------------------------------------------------------

// https://dvcs.w3.org/hg/FXTF/raw-file/tip/filters/index.html
//
// grayscale      ex: filter: grayscale(100%);
// sepia          ex: filter: sepia(100%);
// saturate       ex: filter: saturate(0%);
// hue-rotate     ex: filter: hue-rotate(45deg);
// invert         ex: filter: invert(100%);
// brightness     ex: filter: brightness(15%);
// contrast       ex: filter: contrast(200%);
// blur           ex: filter: blur(2px);

@mixin filter($filter-type,$filter-amount) {
  -webkit-filter: unquote($filter-type+unquote('(#{$filter-amount})'));
  -moz-filter: unquote($filter-type+unquote('(#{$filter-amount})'));
  -ms-filter: unquote($filter-type+unquote('(#{$filter-amount})'));
  -o-filter: unquote($filter-type+unquote('(#{$filter-amount})'));
  filter: unquote($filter-type+unquote('(#{$filter-amount})'));
}

//---------------------------------------------------------
@mixin box-shadow($top, $left, $blur, $size, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $size $color;
    -moz-box-shadow:inset $top $left $blur $size $color;
    box-shadow:inset $top $left $blur $size $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $size $color;
    -moz-box-shadow: $top $left $blur $size $color;
    box-shadow: $top $left $blur $size $color;
  }
}


@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}




// icon fitters
@mixin icon-size($size: $icon-size-m) {
  > svg {
    width: $size;
    height: $size;
  }
}

@mixin icon-size-width($width: $icon-size-m) {
  width: $width;
  > svg {
    width: 100%;
    height: auto;
  }
}

@mixin icon-size-height($height: $icon-size-m) {
  height: $height;
  > svg {
    height: 100%;
    width: auto;
  }
}






@mixin word-wrap() {
  word-break:     break-word;
  -webkit-hyphens: auto;
  -moz-hyphens:    auto;
  hyphens:         auto;
}

@mixin ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}




@mixin prefix($property, $parameters...) {
  @each $prefix in -webkit-, -moz-, -ms-, -o-, "" {
    #{$prefix}#{$property}: $parameters;
  }
}
