.o-divtable {

  @include inuit-font-size(16px);

  &__headerrow {
    position: sticky;
    z-index:1;
    top: 0;
    background-color: $color--white;
    @include flexbox();
    border-bottom: 1px solid $color--gray-40;

    &--sub {
      position: static;
      border-bottom-color: $color--gray-20;
    }
  }

  &__row {
    @include flexbox();
    @include flex-wrap(wrap);
    border-bottom: 1px solid $color--gray-40;
    @include transition(background-color $global-transition-duration $global-transition-timing);

    /*&:hover {
      background-color: lighten($color--black, 97%);
    }*/

    &--sub {
      border-bottom-color: $color--gray-20;

      &[data-language-switch-last-row], &:last-child {
        border-bottom: none;
      }
    }
  }

  &__columnheader {
    @include flex(1 1 auto);
    padding: $inuit-global-spacing-unit-tiny;
    @include inuit-font-size(14px);
    color: $color--gray-60;
    word-break: break-all;
  }

  &__gridcell {
    @include flex(1 1 auto);
    @include align-self(center);
    padding: $inuit-global-spacing-unit-tiny 0;

    @include mq('tablet') {
      padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit-tiny;
    }

    &--top {
        @include align-self(flex-start);
    }
  }


  /* collapsable content */
  &__subrow {
    @include flex(1 1 100%);
    //@include transition(all 2s $global-transition-timing);
    max-width: 100%;
  }

  &__subgridcell {
    @include flex(1 1 auto);
    padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit-tiny;
    border-top: 1px solid $color--gray-20;
  }

}
