.input-search-field {
    border-radius: 5px !important;
    border-color: $color--blue-normal;
    box-shadow: none;
    border-width: 1px;
    padding: 5px;
    outline: 0;

    &:hover,
    &:focus {
        border-color: $color--blue-dark;
    }
}
