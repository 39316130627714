.faq__auto-translated-source-link {
    display: block;
}

.search-container {
    display: flex;
    justify-content: flex-end;
}

.faq__sidebar {
    @include mq($from: tablet) {
        padding-top: 0 !important;
    }
    padding-top: $inuit-global-spacing-unit-large !important;
}
.faq {
    &__list {
        &-row-header {
            cursor: pointer;
        }
        &-row-header:hover {
            background-color: $color--blue-light;
        }
        .faq__documents {
            .o-divtable__row:last-child {
                border-bottom: none;
            }
        }
        // active state
        &-row-header[data-collapsed="false"] {
            background-color: $color--blue-normal;
            .faq__list-row-header-question {
                @extend .u-color--white;
            }
            .faq__list-collapser {
                @include rotate(180);
                &-icon {
                    @extend .u-color--white;
                }
            }
        }

        &-row {
            @include border-radius($global-radius);
            // enable clipping of the inner container
            overflow: hidden;
            border: 1px solid $color--blue-normal;
        }
        &-collapser {
            position:absolute;
            top: 10px;
            right: 20px;
            &-icon {
                @extend .u-color--blue-normal;
            }
        }
    }
}
