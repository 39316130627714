///* ========================================================================
//   #GLOBAL
//   ======================================================================== */

// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

// Set basic colors.

$color--black:              #000;
$color--gray-80:            lighten($color--black, 20%);
$color--gray-60:            lighten($color--black, 40%);
$color--gray-40:            lighten($color--black, 60%);
$color--gray-20:            lighten($color--black, 80%);
$color--white:              #fff;

$color--blue-normal:       #0066b3;
$color--blue-dark:         #004674;
$color--blue-light:        #E9F0F7;

$color--green--success:     #008c2e;
$color--orange--warning:    #d9811a;
$color--yellow--warning:    #f9df99;
$color--red--error:         #e62828;

$sister-product-box-content-grey: #e3e3e3;
$sister-product-box-image-grey: #f4f4f4;

/*
$color--violet:             #6A205F;
$color--violet--light:      #D1B1CC;
$color--gray--light:        #939393;
$color--gray--dark:         #333;
$color--input-border:       #003F69;
$color--cta-button:         #F2E614;
$color--red--error:         #E05A5A;
$color--green--1:           #63E25E;
$color--green--2:           #46D341;
*/
