/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project,
 *    sourced from our default variables. The `font-size` is calculated to exist
 *    in ems, the `line-height` is calculated to exist unitlessly.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */

html {

  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;

  -ms-touch-action: none !important;

  text-rendering: optimizeLegibility;
  font-feature-settings: "kern";
  -webkit-font-feature-settings: "kern";
  -moz-font-feature-settings: "kern";
  -moz-font-feature-settings: "kern=1";
  // override the overflow settings of inuitcss
  overflow-y: auto;
  // synchronize font-size with the legacy styling
  font-size: 18PX;

}

body {

  -webkit-overflow-scrolling: touch;
  padding: 0;
  margin: 0;

  scroll-behavior: smooth;

  //background-color: $color--gray-40;

  //font-family: $font-family--myriad-pro !important;

}
