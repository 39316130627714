/* ==========================================================================
   #DROPDOWNS
   ========================================================================== */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5.
 */

.c-dropdown {

  display: block;
  position: relative;
  width: auto;

  &__select {
    //display: block; /* [1] */
    font: inherit; /* [3] */
    margin: 0; /* [4] */
    cursor: pointer; /* [5] */
    padding: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-large $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-small;
    transition: $global-transition;
    border-radius: $global-radius;
    background-color: transparent;
    border: none !important;
    outline: none !important;
    line-height: 1.3;
    max-width: 100%;
  }

}



/* Style variants
   ========================================================================== */

.c-dropdown--primary {

  &:after {
    position: relative;
    left: -1.5rem;
    display: inline;
    font-family: $font-family--fontawesome;
    content: "\f0d7";
    color: $color--blue-normal;
    transition: $global-transition;
  }

  &:hover {
    &:after {
      color: $color--black;
    }
  }

  .c-dropdown__select {
    @include css3-prefix(appearance, none);
    border: 1px solid rgba($color--blue-normal, 0.5) !important;
    background-color: $color--blue-light;
    color: $color--black;
    font-weight: 700;

    &:hover,
    &:active,
    &:focus {
      //background-color: $color--blue-normal;
      //color: #fff;
      border-color: $color--blue-normal !important;
    }

    &::-ms-expand {
      display: none;
    }
  }

}


.c-dropdown--filter {
  display: flex;
  &:after {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    display: inline;
    font-family: $font-family--fontawesome;
    content: "\f0d7";
    color: $color--black;
    transition: $global-transition;
  }

  &:hover {
    &:after {
      color: $color--black;
    }
  }

  .c-dropdown__select {
    @include css3-prefix(appearance, none);
    border: 1px solid rgba($color--blue-normal, 0.5) !important;
    background-color: $color--white;
    color: $color--black;
    font-weight: 700;

    &:hover,
    &:active,
    &:focus {
      border-color: $color--blue-dark !important;
    }

    &::-ms-expand {
      display: none;
    }
  }
}



/* Size variants
   ========================================================================== */

.c-btn--small {
  padding: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-small;
}

.c-btn--large {
  padding: $inuit-global-spacing-unit $inuit-global-spacing-unit-large;
}
