/* ==========================================================================
   #PATTERN LIBRARY
   ========================================================================== */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5.
 */

.c-pattern {

  margin-top: $inuit-global-spacing-unit;
  margin-bottom: $inuit-global-spacing-unit-large;
  border-bottom: 1PX solid $color--gray-60;

  &__item {
    @include flexbox();
    @include flex-wrap(wrap);
    @include align-items(center);
    padding: $inuit-global-spacing-unit 0;
    border-bottom: 1PX solid $color--gray-20;

    &:first-child {
      border-top: 1PX solid $color--gray-20;
    }
    &:last-child {
      border: none;
    }
  }

  &__definition {
    @include flex(0 0 20%);
    font-weight: bold;
  }

  &__code {
    display: inline-block;
    padding: $inuit-global-spacing-unit-small;
    background-color: $color--blue-light;
    border-radius: $global-radius;
    color: $color--blue-normal;
    //margin-right: $inuit-global-spacing-unit-small;
  }

  &__content {
    @include flex(0 0 80%);
  }


  // color palette
  &__colorpalette {
    height: 50px;
  }
}
