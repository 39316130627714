/* ==========================================================================
   #LINKS
   ========================================================================== */

/**
 * 1.
 * 2.
 * 3.
 */

a {

  font-size: inherit;

  .fas {
    margin-right: $inuit-global-spacing-unit-tiny;
  }

  &.link {
    &__product {
        padding: 0 20px 0 0;
    }
  }
}
