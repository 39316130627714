/**
 * Text alignment
 */
.u-text--left     { text-align: left  !important; }
.u-text--center   { text-align: center!important; }
.u-text--right    { text-align: right !important; }


/**
 * Font weights
 */
.u-weight--ultralight { font-weight: 100 !important; }
.u-weight--light      { font-weight: 300 !important; }
.u-weight--normal     { font-weight: 400 !important; }
.u-weight--semibold   { font-weight: 500 !important; }
.u-weight--bold       { font-weight: 700 !important; }


/**
 * Font sizes
 */
.u-size--tiny {
  @include inuit-font-size(12px);
}
.u-size--small {
  @include inuit-font-size(13px, $important: true);
}
.u-size--normal {
  @include inuit-font-size(15px, $important: true);
}
.u-size--large {
  @include inuit-font-size(16px);
}
.u-size--huge {
  @include inuit-font-size(18px);
}


/**
 * Font color
 */

.u-color--black { color: $color--black !important; }
.u-color--gray-80 { color: $color--gray-80 !important; };
.u-color--gray-60 { color: $color--gray-60 !important; };
.u-color--gray-40 { color: $color--gray-40 !important; };
.u-color--gray-20 { color: $color--gray-20 !important; };
.u-color--white { color: $color--white !important; }

.u-color--blue-normal { color: $color--blue-normal !important; }
.u-color--blue-dark { color: $color--blue-dark !important; }
.u-color--blue-light { color: $color--blue-light !important; }

.u-color--green--success { color: $color--green--success !important; }
.u-color--orange--warning { color: $color--orange--warning !important; }
.u-color--red--error { color: $color--red--error !important; }
