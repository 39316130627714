/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-btn {
  display: inline-block; /* [1] */
  vertical-align: middle; /* [2] */
  font: inherit; /* [3] */
  //text-align: center; /* [4] */
  margin: 0; /* [4] */
  cursor: pointer; /* [5] */
  padding: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-small;
  transition: $global-transition;
  border-radius: $global-radius;
  background-color: transparent;
  border: none !important;
  outline: none !important;

  &__text {
    display: block;
    font: inherit;

    &--primary {
      font: inherit;
    }
    &--secondary {
      @include inuit-font-size(12px);
    }
  }

  .fas {
    margin-right: $inuit-global-spacing-unit-tiny;
  }
}



/* Style variants
   ========================================================================== */

.c-btn--primary {
  background-color: $color--blue-light;
  color: $color--blue-normal;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none; /* [4] */
    background-color: $color--blue-normal;
    color: #fff;
  }

}


.c-btn--secondary {
  text-decoration: none;
  background-color: transparent;
  color: $color--blue-normal;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none; /* [4] */
    background-color: $color--blue-normal;
    color: #fff;
  }

}


.c-btn--tertiary {
  color: $color--blue-normal !important;
  text-decoration: transparent solid underline !important;
  padding: 0;

  &:hover,
  &:active,
  &:focus {
    color: $color--blue-normal !important;
    text-decoration: $color--blue-normal solid underline !important;
  }

}
.c-btn--reset {
    @extend .c-btn--secondary;
}
.c-btn--search {
    background-color: $color--blue-normal;
    color: $color--white;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none; /* [4] */
      background-color: $color--blue-dark;
    }

}



.c-btn--collapser {
  @include flexbox();
  text-align: left;
  color: $color--blue-normal;
  text-decoration: transparent solid underline;
  padding: 0;

  &:hover,
  &:active,
  &:focus {
    color: $color--blue-normal;
    text-decoration: $color--blue-normal solid underline;
  }

  .c-btn__icon {
    display: inline-block;
    @include flex(0 0 auto);
    margin-right: $inuit-global-spacing-unit-tiny;

    i {
      @include transition(all $global-transition-duration $global-transition-timing);
    }
  }
  .c-btn__text {
    @include flex(1 1 auto);
    line-height: 1.2 !important;
  }

  &[data-collapsed='false'] {
    .c-btn__icon {
      i {
        @include transform(rotate(-90deg));
      }
    }
  }

}





/* Size variants
   ========================================================================== */

.c-btn--small {
  padding: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-small;
}

.c-btn--large {
  padding: $inuit-global-spacing-unit $inuit-global-spacing-unit-large;
}





/* Ghost buttons
   ========================================================================== */

/**
 * Ghost buttons have see-through backgrounds and are bordered.
 */

$btn-ghost-border-width: 2px;

.c-btn--ghost {
  border: $btn-ghost-border-width solid currentColor;
  padding: ($inuit-global-spacing-unit-small - $btn-ghost-border-width) ($inuit-global-spacing-unit - $btn-ghost-border-width);

  &,
  &:hover,
  &:active,
  &:focus {
    background: none;
  }

  &.c-btn--small {
    padding: ($inuit-global-spacing-unit-tiny - $btn-ghost-border-width) ($inuit-global-spacing-unit-small - $btn-ghost-border-width);
  }

  &.c-btn--large {
    padding: ($inuit-global-spacing-unit - $btn-ghost-border-width) ($inuit-global-spacing-unit-large - $btn-ghost-border-width);
  }

  &.c-btn--primary {
    color: #4a8ec2;

    &:hover,
    &:focus {
      color: #3774a2;
    }

  }

  &.c-btn--secondary {
    color: #2f4054;

    &:hover,
    &:focus {
      color: #1d2733;
    }

  }

  &.c-btn--tertiary {
    color: #fff;

    &:hover,
    &:focus {
      color: #fff;
    }

  }

}
