.sister-family {}
.sister-product {
    &__link {
        .panel-heading {
            background-color: $sister-product-box-image-grey;
            padding: 5%;
        }
    }
    &__link:hover {
        .panel-content {
            background-color: $sister-product-box-content-grey;
        }
    }
}
